import React, { useState, useRef, useEffect, useMemo } from 'react';
import styles from './index.module.scss';
import data from './data.json';
import pathdata from './path.json';
import LogoHeader from '../../images/en_Logo.png';
import homebtn from '../../images/homebtn.png';
import arrowUp from '../../images/chevron-up.png';
import arrowDown from '../../images/chevron-down.png';
import hongkong from '../../images/ic-office-hongkong.png';
import sg from '../../images/ic-office-singapore.png';
import beijing from '../../images/ic-office-beijing.png';
import wechatIcon from '../../images/ look-line-chat-1.png';
import mailIcon from '../../images/mail.png';
import linkedinIcon from '../../images/linkedin.png';
import personIcon from '../../images/user-plus.png';
import { useParams } from 'react-router-dom';
import daisy from '../../images/daisy.png';
import edwin from '../../images/edwin.png';
import emma from '../../images/emma.png';
import tianhao from '../../images/tianhao.png';
import yuanshuai from '../../images/yuanshuai.png';
import gaoyi from '../../images/gaoyi.png';
import jobs from '../../images/jobs.png';
import tfz from '../../images/tfz.png';
import penglai from '../../images/penglai.png';
import stella from '../../images/stella.png';
import mengcheng from '../../images/mengcheng.png';
import ligong from '../../images/ligong.png';
import kriswu from '../../images/kriswu.png';
import emmawang from '../../images/emmawang.png';
import alai from '../../images/alai.png';
const nameIconMap = {
    daisy,
    alai,
    edwin,
    emma,
    tianhao,
    yuanshuai,
    gaoyi,
    jobs,
    tfz,
    penglai,
    stella,
    mengcheng,
    ligong,
    kriswu,
    emmawang,
};
const BusinessCard: React.FC = () => {
    const [showModal, setShowModal] = useState(false);
    const modalRef = useRef<HTMLDivElement>(null);
    const query = new URLSearchParams(window.location.search);
    const pathName = query.get('name') ?? '';
    const [name, setName] = useState('');

    useEffect(() => {
        if (pathdata[pathName]) {
            setName(pathdata[pathName]);
        }
    }, [pathName]);
    const headerImg = useMemo(() => {
        if (name === '') {
            return '';
        }
        return nameIconMap[name];
    }, [name]);
    const handleContact = () => {
        const query = encodeURIComponent('id=' + name);
        // weixin://dl/business/?appid=*APPID*&path=*PATH*&query=*QUERY
        // window.location.href = `weixin://dl/business/?appid=wx905e58d6b8830dff&path=pages/theme/Page/detail/detail&query=${query}&env_version=release`;
        //pages/activity-none-menu/activity
        const path = `weixin://dl/business/?appid=wx905e58d6b8830dff&path=pages/business-card/index.js&query=${query}&env_version=release`;
        window.location.href = path;
    };
    const handleLinkin = () => {
        // 处理LinkedIn链接的点击事件
        const linkedInUrl = data[name]?.vcard.urls.find(item => item.type === 'linkedin')?.url; // 替换为实际的LinkedIn个人资料URL
        if (linkedInUrl) {
            window.location.href = linkedInUrl;
        }
    };
    const showLinkedIn = () => {
        return data[name]?.vcard.urls.find(item => item.type === 'linkedin')?.url;
    };
    const showSendEmail = () => {
        return data[name]?.vcard.email;
    };

    const handleMail = () => {
        window.location.href = `mailto:${data[name]?.vcard.email}?subject=&body=`;
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                modalRef.current &&
                !modalRef.current.contains(event.target instanceof Node ? event.target : null)
            ) {
                setShowModal(false);
            }
        };

        if (showModal) {
            window.addEventListener('click', handleClickOutside);
        }

        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, [showModal]);
    const downloadVCF = () => {
        const daisy = data[name];
        const photoUrl = 'https://media.ilookcap.com/statics/business_card/' + name + '_head.png';
        const vcfContent = `BEGIN:VCARD
VERSION:3.0
N:${daisy.vcard.name.familyName};${daisy.vcard.name.givenName};;;
FN:${daisy.vcard.fullName}
EMAIL;TYPE=INTERNET:${daisy.vcard.email}
ORG:${daisy.vcard.organization}
TITLE:${daisy.vcard.title}
ADR;TYPE=WORK:;;${daisy.vcard.address.streetAddress};${daisy.vcard.address.locality};;${
            daisy.vcard.address.postalCode
        };${daisy.vcard.address.country}
PHOTO;VALUE=URI:${photoUrl}
${daisy.vcard.urls.map((url, index) => `URL;type=${url.type}:${url.url}`).join('\n')}
END:VCARD`;
        const blob = new Blob([vcfContent], { type: 'text/vcard' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = '艾悦.vcf';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        alert('下载成功');
    };
    return (
        <div className={styles.container}>
            <video
                className={styles['background-video']}
                muted
                aria-hidden="true"
                src="https://media.ilookcap.com/statics/offcial_website/offical.mp4"
                autoPlay
                loop
                playsInline
            />
            <header className={styles.header}>
                <img src={LogoHeader} className={styles.logo}></img>
                <button
                    className={styles['custom-button']}
                    onClick={() => {
                        window.location.href = window.location.origin;
                    }}
                >
                    <img src={homebtn} alt="房子图标" />
                    鹿可官网
                </button>
            </header>
            <div className={styles.profile}>
                <div className={styles.top}>
                    <div className={styles['top-header']}>
                        <img src={headerImg} alt={name} className={styles.profileImg} />
                        <div className={styles.desc}>
                            <div className={styles.name}>{data[name]?.vcard.fullName}</div>
                            {data[name]?.titles.map((title, index) => (
                                <div key={index} className={styles.titles}>
                                    <span>{title.en}</span>
                                    <span>{title.zh}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <ul className={styles.profileInfo}>
                    {data[name]?.desc.map((item, index) => (
                        <li key={index}>{item}</li>
                    ))}
                </ul>
            </div>
            {/* <div className={styles.investment}>
                <div className={styles.investmentItems}>
                    <div className={styles.item}>
                        <img src="path/to/image1.jpg" alt="Investment 1" />
                        <p>2024 Q2 财报分享要点-苹果、微软、谷歌、Meta、亚马逊</p>
                    </div>
                    <div className={styles.item}>
                        <img src="path/to/image2.jpg" alt="Investment 2" />
                        <p>财富自由之后如何生活</p>
                    </div>
                </div>
            </div> */}
            <div className={styles.locations}>
                <div className={styles.locationItem}>
                    <img src={sg}></img>
                    <span>Singapore</span>
                </div>
                <div className={styles.locationItem}>
                    <img src={hongkong}></img>
                    <span>Hongkong</span>
                </div>
                <div className={styles.locationItem}>
                    <img src={beijing}></img>
                    <span>Beijing</span>
                </div>
            </div>
            <div className={styles.slogan}>
                <div className={styles.line}></div>
                <div className={styles.text}>投资有道，生活有品</div>
                <div className={styles.line}></div>
            </div>
            <div className={styles.bottom}>
                <div className={styles.stickyButton}>
                    <button className={styles.contactButton} onClick={() => setShowModal(true)}>
                        联系我<img src={showModal ? arrowUp : arrowDown}></img>
                    </button>
                </div>

                {showModal && (
                    <div className={styles.modal} ref={modalRef}>
                        <div className={styles.modalContent}>
                            <button onClick={handleContact}>
                                <img src={wechatIcon}></img>添加微信
                            </button>
                            {showSendEmail() && (
                                <button onClick={handleMail}>
                                    <img src={mailIcon}></img>发送邮件
                                </button>
                            )}
                            {showLinkedIn() && (
                                <button onClick={handleLinkin}>
                                    <img src={linkedinIcon}></img>连接 Linkedln
                                </button>
                            )}
                            <button onClick={downloadVCF}>
                                <img src={personIcon}></img>添加到通讯录
                            </button>
                        </div>
                    </div>
                )}
                <footer className={styles.footer}>
                    <span>2024 LOOK CAPITAL © Copyright</span>
                </footer>
            </div>
        </div>
    );
};

export default BusinessCard;
